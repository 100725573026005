.ser__container{
    grid-template-columns: repeat(3,270px);
    justify-content: center;
    column-gap: 1.8rem;
}
.ser__content{
    position: relative;
    border: 1px solid rgba(0,0,0,0.1);
    background-color: var(--container-color);
    padding: 6rem 0 2.5rem;
}
.ser__icon{
        display: block;
    font-size: 2rem;
    color: var(--title-color);
    margin-bottom:var(--mb-1) ;
}
.ser__title{
          font-size: var(--h3-font-size);
    margin-bottom: var(--mb-1);
    font-weight: var(--font-medium);
}
.ser__button{
    color: var(--title-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    cursor: pointer;
}
.ser__button-icon{
    font-size: 1rem;
    transition: 0.3s;
}
.ser__button:hover .ser__button-icon{
   transform:translateX(00.25rem);
}

.ser__modal{
   position: fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}
.ser__modal-content{
    width: 500px;
    position: relative;
    background-color: var(--container-color);
    padding: 4.5rem 2.5rem 2.5rem;
    border-radius: 1.5rem;
}
.ser__modal-close{
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
}
.ser__modal-title,
.ser__modal-description{
    text-align: center;
}
.ser__modal-title{
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1);
}
.ser__modal-description{
    font-size: var(--small-font-size);
    padding: 0 3.5rem;
    margin-bottom: var(--mb-2);
}
.ser__modal-service{
      row-gap: 0.75rem;
}
.ser__modal-service{
    display: flex;
    align-items: center;
    column-gap: .5rem;
}
.ser__modal-icon{
    color: var(--title-color);
    font-size: 1.1rem;
}
.ser__modal-info{
    font-size: var(--small-font-size);
}
.active-modal{
    opacity: 1;
    visibility: visible;
}
          /*========================== BREAKPOINTS =============== */
@media screen and (max-width: 992px) {
    .ser__container{
        grid-template-columns: repeat(3,218px);
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    .ser__container{
        grid-template-columns: repeat(2,1fr);
    }
}

@media screen and (max-width: 576px) {
    .ser__modal-content{
        padding: 4.5rem 1.5rem 2.5rem;
    }
    .ser__modal-description {
        padding: 0;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .ser__container{
       grid-template-columns: max-content;
    }
    .ser__content{
                 padding-right: 6rem;
    }
}