.qualification__container{
    max-width: 768px;
}
.qual__tabs{
    display: flex;
    justify-content: center;
    margin-bottom: var(--mb-2);
}
.qual__button{
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    color: var(--title-color);
    margin: 0 var(--mb-1);
    cursor: pointer;
}
.qual__button:hover{
    font-size: 1.8rem;
    margin-right: var(--mb-0-25);
}
.qual__active{
    color: var(--title-color-dark);
}
.qual__sections{
    display: grid;
    grid-template-columns: 0.5fr;
    justify-content: center;
}
.qual__content{
    display: none;
}
.qual__content-active{
    display: block;
}
.qual__data{
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
}
.qual__title{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}
.qual__subtitle{
    display: inline-block;
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-1);
}
.qual__calender{
    font-size: var(--small-font-size);
}
.qual__rounder{
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: var(--text-color);
    border-radius: 50%;
}
.qual__line{
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--text-color);
    transform: translate(6px, -7px);

}
/*========================== BREAKPOINTS =============== */
@media screen and (max-width: 992px) {
 .qualification__container{
     margin-left: auto;
     margin-right:auto;
 }


}

/* For medium devices */
@media screen and (max-width: 768px) {
    .qualification__container{
        margin-left: var(--mb-1-5);
        margin-right:var(--mb-1-5);
    }


}

@media screen and (max-width: 576px) {
    .qualification__container{
       grid-template-columns: initial;

    }
    .qual__button{
        margin: 0 var(--mb-0-75);
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
.qual__data{
    gap: 0.5rem;
}
}